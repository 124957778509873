<template>
  <v-container fluid>
    <v-row>
      <v-breadcrumbs :items="breadCrumbsItems" large>
        <template v-slot:divider>
          <v-icon size="11">mdi-greater-than</v-icon>
        </template>
      </v-breadcrumbs>
    </v-row>

    <v-row>
      <v-col cols="12" md="3">
        <ManufacturerMenu></ManufacturerMenu>
      </v-col>

      <v-col cols="12" md="9" class="mt-3">
        <v-row class="rowBg" v-if="userDetails.isActive">
          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Payments (0)</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #61BDBD 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Shipment (0)</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #DED84C 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Awaiting Delivery (0)</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #618BBD 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Deleted Orders (0)</p>
          </v-col>

          <v-col
            cols="11"
            md="2"
            class="orderStatBox"
            style="background: linear-gradient(192.88deg, #111212 43.53%, #B55620 90.69%);color:#ffffff; "
          >
            <p class="orderStatText">Complaints (0)</p>
          </v-col>
        </v-row>

        <v-row class="rowBg mt-10" v-if="userDetails.isActive">
          <v-col cols="12" md="4">
            <v-text-field
              v-model="orderSearch.orderNumber"
              :rules="orderNumberRules"
              :counter="10"
              solo
              label="Order number"
              required
            ></v-text-field>
            <v-text-field
              v-model="orderSearch.sellerName"
              solo
              label="Seller Name"
              v-if="this.filters == true"
            ></v-text-field>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="orderSearch.date"
              transition="scale-transition"
              offset-y
              min-width="auto"
              v-if="this.filters == true"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="orderSearch.date"
                  label="Date range"
                  prepend-icon="mdi-calendar"
                  readonly
                  solo
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="orderSearch.date" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(orderSearch.date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              v-model="orderSearch.product"
              solo
              label="Product"
            ></v-text-field>
            <v-text-field
              v-model="orderSearch.trackingNumber"
              solo
              label="Tracking Number"
              v-if="this.filters == true"
            ></v-text-field>
            <v-select
              :items="statusItems"
              v-model="orderSearch.Status"
              label="Status"
              solo
              v-if="this.filters == true"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <div align="center">
              <v-btn align="center" class="buttonStyle" large>
                Search
              </v-btn>
            </div>
            <div class="mt-3" align="center">
              <p style="color:#4EA8DB">
                More Filters
                <v-icon color="black" dark @click="toggleFilters"
                  >mdi-arrow-down-drop-circle</v-icon
                >
              </p>
            </div>
          </v-col>
        </v-row>

        <v-row class="rowBg mt-10" v-if="userDetails.isActive">
          <v-col cols="12">
            <v-data-table
              :headers="headers"
              :items="searchResult"
              :search="search"
              hide-default-footer
            >
              <template slot="items" slot-scope="props">
                <td class="text-xs-left">{{ props.item.product }}</td>
                <td class="text-xs-left">{{ props.item.product_action }}</td>
                <td class="text-xs-left">{{ props.item.order_status }}</td>
                <td class="text-xs-left">
                  <v-btn icon class="mx-0" slot="activator">
                    <v-icon color="teal">edit</v-icon>
                  </v-btn>

                  <v-btn
                    icon
                    class="mx-0"
                    @click="deleteClassLevel(props.item.id)"
                  >
                    <v-icon color="red">delete</v-icon>
                  </v-btn>
                </td>
              </template>
              <v-alert
                slot="no-results"
                :value="true"
                color="error"
                icon="warning"
              >
                Your search for "{{ search }}" found no results.
              </v-alert>
            </v-data-table>
          </v-col>
        </v-row>

        <v-row wrap v-else class="mb-10">
          <v-col cols="12" align="Center">
              <v-layout wrap>
                <v-col cols="12" align="Center">
                  <v-icon size="200" color="red">mdi-alert-circle</v-icon>
                </v-col>
              </v-layout>
              <h3>Account awaiting approval</h3>
              <v-layout wrap>
                <v-col cols="12" md="10" offset-md="1" align="Center">
                   <p>Hello <b>{{userDetails.name}}</b>! We are glad to have you sign up on qikPharma as a <b>Manufacturer</b>. Your account is currently under review and will be approved sooner than you can imagine.</p>
                </v-col>
              </v-layout>
             
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ManufacturerMenu from "../others/ManufacturerMenu.vue";

export default {
  name: "ManufacturersOrderList",

  components: {
    ManufacturerMenu,
  },

  data: () => ({
    breadCrumbsItems: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "My Account",
        disabled: false,
        href: "/",
      },
      {
        text: "Order List",
        disabled: true,
        href: "/en/distributor/orders",
      },
    ],
    filters: false,
    menu: false,
    search: "",
    searchResult: [],
    orderSearch: {
      orderNumber: "",
      product: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISO,
    },
    userDetails:{
      isActive:true,
    },
    orderNumberRules: [
      (v) => !!v || "Order number is required",
      (v) => v.length <= 10 || "Number must be less than 10 characters",
    ],
    headers: [
      {
        text: "Product",
        align: "left",
        sortable: false,
        value: "product",
      },
      { text: "Product Action", value: "productaction" },
      { text: "Order Status", value: "orderstatus" },
      { text: "Order Action", value: "action" },
    ],
    statusItems: [
      "All Orders",
      "Awaiting Payment",
      "Awaiting Cancellation",
      "Awaiting Delivery",
      "Order Complete",
      "Frozen Orders",
      "Payment not confirmed",
    ],
  }),
  created (){
      this.user_id = localStorage.getItem("userId");
      this.getUserDetails();
  },

  methods: {
    toggleFilters() {
      this.filters = !this.filters;
    },
    getUserDetails() {
      this.$http
        .get(`${process.env.VUE_APP_URL}user/` + localStorage.getItem("userId"))
        .then((response) => {
          this.userDetails = response.data.data;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style scoped>
.rowBg {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 20px;
}

.orderStatBox {
  padding-top: 30px !important;
  border-radius: 10px;
  text-align: center;
  margin: 10px;
}

.orderStatText {
  color: white;
  font-size: 16px;
  text-align: center;
}

.buttonStyle {
  background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
  border-radius: 10px;
  color: #ffffff !important;
  font-size: 16px;
  padding-left: 45px !important;
  padding-right: 45px !important;
}
</style>
